<template>
  <div>
    <!-- 应用管理 -->
    <!-- <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.systemApplyName" clearable placeholder="请输入应用名称" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryParams.systemApplyManager" clearable placeholder="请输入应用负责人" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form> -->
    
    <div class="content-wrap">
      <div class="btn-wrap cfx">
        <el-button type="success" size="small" icon="el-icon-plus" v-if="OA.includes('jcxx:yygl:add')" @click="handleForm(false)">添加</el-button>
      </div>
      <div v-if="applyTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <!--列表-->
      <paged-table :data="applyList" :total="applyTotal" :loading="tLoading" v-if="applyTotal>0"
        :isShowSelection="false" defaultSortProp="id" v-model="queryParams"
        @refresh="refresh"> 
        <el-table-column sortable="custom" prop="code" show-overflow-tooltip label="应用编号"></el-table-column>
        <el-table-column sortable="custom" prop="name" show-overflow-tooltip label="应用名称"></el-table-column>
        <el-table-column sortable="custom" prop="remark" show-overflow-tooltip label="备注"></el-table-column>
        <el-table-column sortable="custom" prop="addTime" show-overflow-tooltip label="创建时间"></el-table-column>
        <el-table-column sortable="custom" prop="extend.addUserName" show-overflow-tooltip label="创建者"></el-table-column>
        <el-table-column align="right" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button v-if="OA.includes('jcxx:yygl:load')" size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
            <el-button v-if="OA.includes('jcxx:yygl:editor')" size="mini" type="primary" @click="handleForm(true, scope.row)" icon="el-icon-edit" title="编辑"></el-button>
            <el-button v-if="OA.includes('jcxx:yygl:del')" size="mini" type="danger" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除"></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑应用' : '添加应用'" :visible.sync="aVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="120px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="应用编号：" prop="code">
          <el-input placeholder="请输入应用编号" style="width:316px;" v-model="form.code" clearable></el-input>
        </el-form-item>
        <el-form-item label="应用名称：" prop="name">
          <el-input placeholder="请输入应用名称" style="width:316px;" v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input placeholder="请输入备注" style="width:316px;" v-model="form.remark" clearable :maxlength="11"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情界面 -->
    <el-dialog title="应用详情" :visible.sync="bVisible" width="550px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="应用编号：" prop="code">
          <span v-text="details.code"></span>
        </el-form-item>
        <el-form-item label="应用名称：" prop="name">
          <span v-text="details.name"></span>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <span v-text="details.remark"></span>
        </el-form-item>
        <el-form-item label="创建时间：" prop="addTime">
          <span> {{ details.addTime }} </span>
        </el-form-item>
        <el-form-item label="创建者：" prop="addUserName">
          <span> {{ details.extend.addUserName }} </span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import datePicker from "@/mixins/datePicker";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'applyManage',
  components: {
    PagedTable
  },
  mixins: [pageMixin, datePicker],
  data() {
    return {
      queryParams: {
      },

      form:{
        id:'',
        code:'',
        name:'',
        remark:'',
      },
      details:{
        code:'',
        name:'',
        addTime: '',
        remark:'',
        extend: {
          addUserName: ''
        },
      },
      formRules:{
        code: [{ required: true, message: '请输入应用编号', trigger: 'blur' }],
        name: [{ required: true, message: '请输入应用名称', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
      },

      pageApi:'systemApplyList',
      insertApi:'systemApplyAdd',
      editorApi:'systemApplyEdit',
      deleteApi:'systemApplyDelete',
      loadApi:'systemApplyLoad',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("systemApply", ["applyList", "applyTotal"]),
  },
  methods: {
    ...mapActions("systemApply",["systemApplyList", "systemApplyAdd", "systemApplyEdit", "systemApplyDelete", "systemApplyLoad"]),
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
  }
};
</script>